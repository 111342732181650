import React from 'react';
import Header from './header';
import ListaProcessos from './ListaProcessos';
import { connect } from 'react-redux';
import { logout, mudarUser,  mudarLoadingHome,mudarFaculdade } from '../actions/AppActions';
import { URL } from '../variables';
// import io from 'socket.io-client';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Sala from './Sala';
import Redacoes from './Redacoes';

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
    }
  />
);
class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // redirect:'/not_processo',

    }
    this.videoRef = React.createRef();
    this.imageRef = React.createRef();

    // this.videoRef=null;
  }
  logout() {
    localStorage.removeItem('token');
    // this.setState({redirect:true,path:'/'});
    this.props.logout();
  }

  componentDidMount() {
    this.props.mudarLoadingHome(true);

    this.get_user();

  }

  async get_user() {

    fetch(`${URL}api/corretor/get_user/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',

        'Authorization': `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);

          // this.props.mudarLoadingHome(false);
          setTimeout(()=>{
            this.get_user();
          },5000);
        }
        else {
          this.props.mudarUser(resp.user);
          this.props.mudarFaculdade(resp.faculdade);
          this.props.mudarLoadingHome(false);

        }




      } catch (err) {
        console.log(err);
        // this.props.mudarLoadingHome(false);
        setTimeout(()=>{
          this.get_user();
        },5000);

      }

    })
      .catch((err) => {
        console.log(err);
        // this.props.mudarLoadingHome(false);
        setTimeout(()=>{
          this.get_user();
        },5000);
      });
  }

  componentDidUpdate() {
    if (this.props.stream != null && this.videoRef.current != null) {
      this.videoRef.current.srcObject = this.props.stream
    }
  }

  render() {
    return (
      <div>
        {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
        <img ref={this.imageRef} src="/imgs/eu.jpeg" hidden />
        <div id="wrapper">
          <Header />
          {this.props.loading_home == false && <div className="d-none d-sm-block">
            <div className="content-page" style={{ marginLeft: 0 }}>
              <div className="content">
                

                <div className="container-fluid">
                  <div className="page-title-box">
                    <div className="row align-items-center">
                      <div className="col-sm-12">
                        <h2 style={{ color: 'black' }}>{this.props.faculdade.name}</h2>
                      </div>
                    </div>
                  </div>
                  {/* {this.props.processo != null &&
                   } */}
                  <Switch>
                    
                    <Route exact path={`${this.props.match.url}`} render={(props2) => <ListaProcessos {...props2} />} />
                    <Route exact path={`${this.props.match.url}sala/:id`} render={(props2) => { return (<Sala {...props2} />) }} />
                    <Route exact path={`${this.props.match.url}redacoes/:id`} render={(props2) => { return (<Redacoes {...props2} />) }} />

                    <OtherRoute path="*" path_name='/' />

                  </Switch>
                  {/* { this.props.children } */}
                  {/* {this.props.processo == null &&
                    <NotProcesso />} */}
                </div>
              </div>
            </div>
          </div>}
          {this.props.loading_home == true && <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              <h4>Carregando dados. Por favor, aguarde.</h4>
            </div>
          </div>}

        </div>
        <div className="d-flex d-sm-none" style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
          <div>

            <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>Deslogue e acesse pelo computador para poder visualizar nosso conteúdo
          </p>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <a className="btn btn-success btn-lg" onClick={this.logout.bind(this)} style={{ width: '80%', color: 'white' }}><i
                className="mdi mdi-power " style={{ color: 'white' }}></i> Sair</a>
            </div>
          </div>

        </div>
      </div >

    );
  }


}

const mapsStateToProps = (state) => (
  {
    faculdade: state.AppReducer.faculdade,
    token: state.AppReducer.token,
    loading_home: state.AppReducer.loading_home,
    stream: state.AppReducer.stream,
    processo: state.AppReducer.processo,
  }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarLoadingHome,mudarFaculdade })(Home);
