export const login = (data) => (
    {
        type: 'login',
        payload: data
    }
);

export const logout = () => (
    {
        type: 'login',
        payload: ''
    }
);


export const mudarUser = (data) => (
    {
        type: 'user',
        payload: data
    }
);

export const mudarLoadingHome = (data) => (
    {
        type: 'loading_home',
        payload: data
    }
);

export const mudarFaculdade = (data) => (
    {
        type: 'faculdade',
        payload: data
    }
);



export const mudarSocket = (data) => (
    {
        type: 'socket',
        payload: data
    }
);

export const mudarSala = (data) => (
    {
        type: 'sala',
        payload: data
    }
);

export const mudarProcesso = (data) => (
    {
        type: 'processo',
        payload: data
    }
);

export const mudarMensagens = (data) => (
    {
        type: 'mensagens',
        payload: data
    }
);
