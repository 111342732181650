import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Stage, Layer, Rect, Label, Text } from "react-konva";

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const DrawAnnotations = (props) => {
    // const [annotations, setAnnotations] = useState([]);
    const annotations = props.annotations;
    const [newAnnotation, setNewAnnotation] = useState([]);

    const handleMouseDown = event => {
        if (newAnnotation.length === 0) {
            const { x, y } = event.target.getStage().getPointerPosition();
            setNewAnnotation([{ x, y, width: 0, height: 0, key: "0" }]);
        }
    };

    const handleMouseUp = event => {
        if (newAnnotation.length === 1) {
            const sx = newAnnotation[0].x;
            const sy = newAnnotation[0].y;
            const { x, y } = event.target.getStage().getPointerPosition();
            if (x - sx > 0 && y - sy > 0) {
                const annotationToAdd = {
                    x: sx,
                    y: sy,
                    width: x - sx,
                    height: y - sy,
                    key: annotations.length + 1,
                    mouse_inside: false,
                    id: uuidv4()
                };
                props.newAnnotation(annotationToAdd);
                // annotations.push(annotationToAdd);

            }
            setNewAnnotation([]);
            // setAnnotations(annotations);
        }
    };

    const handleMouseMove = event => {
        if (newAnnotation.length === 1) {
            const sx = newAnnotation[0].x;
            const sy = newAnnotation[0].y;
            const { x, y } = event.target.getStage().getPointerPosition();
            setNewAnnotation([
                {
                    x: sx,
                    y: sy,
                    width: x - sx,
                    height: y - sy,
                    key: "0"
                }
            ]);
        }
    };

    const annotationsToDraw = [...annotations, ...newAnnotation].filter((item, id) => item.width != 0 && item.height != 0);
    // console.log(annotationsToDraw);
    return (
        <Stage
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            width={710}
            height={1030}
            style={{ position: 'absolute', top: "-30px", zIndex: 3, cursor: 'crosshair' }}
        >
            <Layer>
                {annotationsToDraw.map((value, id) => {
                    return (
                        <Rect
                            key={id}
                            onClick={() => {
                                console.log('click');
                                props.showEditAnnotation(id);
                            }}
                            x={value.x}
                            y={value.y}
                            width={value.width}
                            height={value.height}
                            fill="transparent"
                            stroke={((value.mouse_inside == true || (props.annotation_edit != null && props.annotation_edit.id == value.id)) ? 'darkgoldenrod' : "red")}
                            onMouseEnter={(e) => {
                                if (newAnnotation.length == 0) {
                                    props.MouseEnter(id, true);
                                    console.log('enter');
                                }

                            }}

                            onMouseLeave={(e) => {
                                if (newAnnotation.length == 0) {
                                    props.MouseEnter(id, false);
                                    console.log('leave');
                                }
                            }}
                        />

                    );
                })}


                {annotationsToDraw.map((value, id) => {
                    return (
                        <Text
                        key={id}

                            onClick={() => {
                                console.log('click');
                                props.showEditAnnotation(id);
                            }}
                            x={value.x}
                            y={value.y - 16}
                            width={value.width}
                            text={(id+1)+')'}
                            fontSize={14}
                            fontFamily='Calibri'
                            fill='black'
                            fontStyle='bold'
                            align='center'
                            // width={value.width}
                            // height={value.height}
                            // fill="transparent"
                            // stroke={((value.mouse_inside==true || (props.annotation_edit!=null && props.annotation_edit.id==value.id))?'darkgoldenrod': "red")}
                            onMouseEnter={(e) => {
                                if (newAnnotation.length == 0) {
                                    props.MouseEnter(id, true);
                                    console.log('enter');
                                }

                            }}

                            onMouseLeave={(e) => {
                                if (newAnnotation.length == 0) {
                                    props.MouseEnter(id, false);
                                    console.log('leave');
                                }
                            }}


                        />



                    );
                })}

                {props.new_anotation != null &&
                    <Rect
                        // onClick={() => {
                        //     console.log('click');
                        //     props.showEditAnnotation(id);
                        // }}
                        x={props.new_anotation.x}
                        y={props.new_anotation.y}
                        width={props.new_anotation.width}
                        height={props.new_anotation.height}
                        fill="transparent"
                        stroke="darkgoldenrod"
                        name='teste'
                    // onMouseEnter={(e)=>{
                    //     console.log('enter');
                    // }}

                    // onMouseLeave={(e)=>{
                    //     console.log('leave');
                    // }}
                    />

                }
            </Layer>
        </Stage>
    );
};

export default DrawAnnotations;