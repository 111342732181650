import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';

import moment from 'moment-timezone';
import { Modal, Button } from 'react-bootstrap'

class ModalErrors extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: false,
            show_video: false,
            show_solicitar: true,
            show_aceitar: false,
            stream: null,
            redirect: false,
            path: '/sala',
            processos: [],
            candidato_id: '',
            loading: false,
            comentario: '',
            msgErro: '',
            aspectos: [
                { name: 'Adequação vocabular (A)', value: false,sigla:'A' },
                { name: 'Pontuação (P)', value: false,sigla:'P' },
                { name: 'Sintaxe, morfossintaxe, concordância e regência verbal e nominal (S)', value: false,sigla:'S' },
                { name: 'Ortografia oficial vigente, acentuação gráfica (O)', value: false,sigla:'O' },
            ]
        }
        this.aspectos = [
            { name: 'Adequação vocabular (A)', value: false,sigla:'A' },
            { name: 'Pontuação (P)', value: false,sigla:'P' },
            { name: 'Sintaxe, morfossintaxe, concordância e regência verbal e nominal (S)', value: false,sigla:'S' },
            { name: 'Ortografia oficial vigente, acentuação gráfica (O)', value: false,sigla:'O' },
        ];
    }
    componentDidMount() {
        // this.get_processos();

    }

    componentWillUnmount() {
        // clearTimeout(this.logs_timeout);
    }


    componentDidUpdate(props){
        if(this.props.annotation_edit!=null && props.annotation_edit!=this.props.annotation_edit){
            console.log(this.props.annotation_edit);
            let aspectos=[...this.props.annotation_edit.data.aspectos];
            let comentario = this.props.annotation_edit.data.comentario;
            this.setState({aspectos,comentario});
        }
    }






    render() {
        // let sala_id = this.props.sala != '' ? this.props.sala.id : '';
        // let candidatos = this.props.candidatos.filter((item, id) => {
        //     if (item.sala_id != sala_id) {
        //         return false;
        //     }
        //     return true;
        // });
        return (
            <>
                {/* <Button variant="primary" onClick={() => this.setState({ show_modal_action: true,candidato_id:'',acao:'' })}>
                    Reportar Ação Suspeita
                </Button> */}


                <Modal
                    show={this.props.show_modal_aspect}
                    onHide={() => {
                        this.props.setState({ show_modal_aspect: false });
                        this.setState({comentario:'',aspectos:[
                            { name: 'Adequação vocabular (A)', value: false,sigla:'A' },
                { name: 'Pontuação (P)', value: false,sigla:'P' },
                { name: 'Sintaxe, morfossintaxe, concordância e regência verbal e nominal (S)', value: false,sigla:'S' },
                { name: 'Ortografia oficial vigente, acentuação gráfica (O)', value: false,sigla:'O' },
                        ],msgErro:'' });

                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <span style={{ color: 'black' }}>Qual aspecto está sendo avaliado nesta marcação?</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.msgErro != '' && <div>
                            <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                            </div>
                            <br />

                        </div>}
                        <div className="row">
                            {this.state.aspectos.map((item, id) => (
                                <div key={id} className="col-6" >
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" onChange={(e) => {
                                            let aspectos = [...this.state.aspectos];
                                            aspectos[id].value = e.target.checked;
                                            this.setState({ aspectos: aspectos });
                                        }} type="checkbox" checked={item.value} />
                                        <label className="form-check-label" style={{
                                            fontWeight: 'normal',
                                            color: 'black',
                                            fontSize: '13px'
                                        }}>{item.name}</label>
                                    </div>
                                   


                                </div>
                            ))}
                        </div>
                        {/* <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Selecione um candidato</label>
                            <div className="col-sm-12">
                                <select value={this.state.candidato_id} className="custom-select" id="cameras" onChange={(e) => {
                                    this.setState({ candidato_id: e.target.value })
                                }}>
                                    <option value={''}>Selecione um candidato</option>

                                    {candidatos.map((item, id) => (
                                        <option key={id} value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div> */}
                        <div className="form-group row">

                            <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: 16 }}>Comentário <span style={{ color: "red", fontWeight: "700" }}></span></label>
                            <div className="col-sm-12">
                                <textarea className="form-control" value={this.state.comentario} placeholder="Comentário" type="text" onChange={(e) => this.setState({ comentario: e.target.value })} />

                            </div>
                        </div>
                    </Modal.Body>
                    {this.state.loading == false && <Modal.Footer>
                        <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
                        <div>
                        {this.props.annotation_edit!=null && <Button variant="danger" className="btn-lg" onClick={() => {
                            this.props.removeAnotation(this.props.annotation_edit);
                            this.props.setState({ show_modal_aspect: false });
                            this.setState({comentario:'',aspectos:[
                                { name: 'Adequação vocabular (A)', value: false,sigla:'A' },
                                { name: 'Pontuação (P)', value: false,sigla:'P' },
                                { name: 'Sintaxe, morfossintaxe, concordância e regência verbal e nominal (S)', value: false,sigla:'S' },
                                { name: 'Ortografia oficial vigente, acentuação gráfica (O)', value: false,sigla:'O' },
                            ],msgErro:''});
                        }}>Remover</Button>}
                        </div>
                        <div style={{display:'flex'}}>
                        <Button variant="secondary" className="btn-lg" onClick={() => {
                            this.props.setState({ show_modal_aspect: false });
                            this.setState({comentario:'',aspectos:[
                                { name: 'Adequação vocabular (A)', value: false,sigla:'A' },
                                { name: 'Pontuação (P)', value: false,sigla:'P' },
                                { name: 'Sintaxe, morfossintaxe, concordância e regência verbal e nominal (S)', value: false,sigla:'S' },
                                { name: 'Ortografia oficial vigente, acentuação gráfica (O)', value: false,sigla:'O' },
                            ],msgErro:''});
                        }}>Fechar</Button>
                        <Button className="btn-lg" style={{marginLeft:5}} onClick={() => {

                            let market=false;
                            this.state.aspectos.map((item,id)=>{
                                if(item.value==true){
                                    market=true;
                                }
                            });
                            if(market==false){
                                this.setState({msgErro:'Selecione pelo menos um aspecto'});
                                return;
                            }
                            this.setState({comentario:'',aspectos:[
                                { name: 'Adequação vocabular (A)', value: false,sigla:'A' },
                { name: 'Pontuação (P)', value: false,sigla:'P' },
                { name: 'Sintaxe, morfossintaxe, concordância e regência verbal e nominal (S)', value: false,sigla:'S' },
                { name: 'Ortografia oficial vigente, acentuação gráfica (O)', value: false,sigla:'O' },
                            ],msgErro:''});

                            this.props.onSave({comentario:this.state.comentario,aspectos:[...this.state.aspectos],annotation_edit:this.props.annotation_edit});
                            this.props.setState({ show_modal_aspect: false });


                        }}>Salvar</Button>
                        </div>
                        </div>

                    </Modal.Footer>}
                    {this.state.loading == true && <Modal.Footer><div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div></div></Modal.Footer>}
                </Modal>

            </>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
    }
);

export default connect(mapsStateToProps, { logout })(ModalErrors);
