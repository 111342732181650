import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout, mudarProcesso, mudarSocket } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';

import moment from 'moment-timezone';
import ListaCandidatos from './ListaCandidatos';

class Sala extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: true,
            redirect: false,
            path: '/',
            salas: [],
            sala_select: {},

        }

        // this.videoRef=null;
    }


    componentDidMount() {
        this.get_salas(this.props.match.params.id);

    }

    componentWillUnmount() {

    }

    async get_salas(id) {
        this.setState({ loading_screen: true });
        fetch(`${URL}api/corretor/get_salas/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);
                    setTimeout(() => {
                        this.get_salas(this.props.match.params.id);
                    }, 5000);
                }
                else {
                    this.props.mudarProcesso(resp.processo);

                    this.setState({ loading_screen: false, salas: resp.salas });

                }




            } catch (err) {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_salas(this.props.match.params.id);
                }, 5000);

            }

        })
            .catch((err) => {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_salas(this.props.match.params.id);
                }, 5000);
            });
    }


    render() {
        let index = 0;

        return (
            <div>

                {this.state.loading_screen == false && <div className="row">
                    {this.state.redirect == true && <Redirect to={this.state.path} />}


                    {/* <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.faculdade.name}</h4>
                                <p style={{ fontSize: '1rem', color: 'black' }}>Nome do Fiscal: {this.props.user.name}</p>

                                <h4 style={{ color: 'black' }}>Comunicado Geral</h4>


                                <div className="d-flex" style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
                                    <textarea id="msg_comunicado" placeholder="Escreva um comunicado Geral para enviar aos candidatos" className="form-control" style={{ resize: 'none', height: '100px' }} />
                                    <button className="btn btn-primary" id="enviar_comunicado">
                                        Enviar
                                        </button>
                                </div>

                            </div>
                        </div>
                    </div> */}
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body" >
                                <div className="row" style={{ padding: '1.25rem' }}>

                                    <div className="col-12" style={{ marginBottom: '1rem' }}>
                                        <div style={{ padding: '1.25rem', position: 'absolute', right: 0, width: '150px' }}>
                                            <Link onClick={() => {

                                            }} to='/' className="btn btn-secondary btn-outline btn-circle m-r-5" style={{ width: '105px' }}><i
                                                className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Voltar</i></Link>


                                        </div>
                                        <h4 style={{ color: 'black' }}>Lista de Salas</h4>
                                        <p className="m-b-20" style={{ color: 'black', whiteSpace: 'pre-line' }}>{this.props.processo.name}
                                        </p>

                                    </div>
                                    {/* <div style={{width:'100%'}}>
                                        <Tabs
                                            id="controlled-tab-example"
                                            activeKey={this.state.tab}
                                            onSelect={(k) => this.setState({ tab: k })}
                                        >
                                            {this.state.salas.map((item, id) => {
                                                index += 1;
                                                return (
                                                    <Tab tabClassName={'tab'} key={id} eventKey={index - 1} title={'Sala ' + item.numero}>
                                                        <ListaCandidatos sala_id={item.id} sala_numero={item.numero} />
                                                    </Tab>
                                                );

                                            })}

                                        </Tabs>
                                    </div> */}
                                    <div className="col-12">
                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <table id="tech-companies-1" className="table table-striped">
                                                    <thead>
                                                        <tr>

                                                            <th>Número</th>
                                                            <th>Redações Corrigidas</th>
                                                            {/* <th data-priority="1">Descrição</th> */}

                                                            <th data-priority="3">Ações</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.salas.map((item, id) => (

                                                            <tr key={id}>

                                                                <th>Sala {item.sala.numero}</th>
                                                                {/* <td ><div dangerouslySetInnerHTML={{__html:item.descricao}}></div></td> */}
                                                                <th>{item.correcoes} de {item.textos}</th>

                                                                <td>
                                                                    <Link to={'/redacoes/' + item.sala.id}><span className="btn btn-success btn-outline btn-circle m-r-5"
                                                                        data-toggle="tooltip"
                                                                    ><i className="mdi mdi-eye"></i></span></Link>
                                                                </td>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {this.state.salas.length == 0 && <div className="row w-100">
                                            <div className="col-12">
                                                <p style={{ textAlign: 'center' }}>Você não está vinculado a nenhuma sala</p>

                                            </div>
                                        </div>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>}
                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
    }
);

export default connect(mapsStateToProps, { mudarUser, logout, mudarProcesso, mudarSocket })(Sala);
