import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';

import moment from 'moment-timezone';

class ListaCandidatos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: false,
            show_video: false,
            show_solicitar: true,
            show_aceitar: false,
            stream: null,
            redirect: false,
            path: '/sala',
            candidatos: [],
            candidatos_nao_finalizados: [],

        }

        // this.videoRef=null;
    }
    componentDidMount() {
        this.get_candidatos();

    }

    componentWillUnmount() {
    }

    async get_candidatos() {
        this.setState({ loading_screen: true });
        fetch(`${URL}api/corretor/get_candidatos/${this.props.sala_id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);

                }
                else {
                    this.setState({ loading_screen: false, candidatos: resp.candidatos,candidatos_nao_finalizados:resp.candidatos_nao_finalizados });

                }




            } catch (err) {
                console.log(err);
                // this.props.mudarLoadingHome(false);


            }

        })
            .catch((err) => {
                console.log(err);
                // this.props.mudarLoadingHome(false);

            });
    }







    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}


                <div className="col-12">
                <br />

                    <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black',marginTop:'1rem',textAlign:'center' }}>Sala {this.props.sala_numero}</h4>
                    <br />
                    <br />

                    {/* <hr /> */}
                    {this.state.loading_screen == false && <div>

                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <table id="tech-companies-1" className="table table-striped">
                                    <thead>
                                        <tr>

                                            <th>Identificação</th>
                                            <th>Status</th>
                                            {/* <th data-priority="1">Descrição</th> */}

                                            <th data-priority="3">Ações</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.candidatos.map((item, id) => (

                                            <tr key={id}>

                                                <th>#{item}</th>
                                                {/* <td ><div dangerouslySetInnerHTML={{__html:item.descricao}}></div></td> */}
                                                {this.state.candidatos_nao_finalizados.includes(item)?<td style={{color:"red"}}>Não Finalizada a Correção</td>:<td style={{color:"green"}}>Correção Finalizada</td>}

                                                <td>
                                                    <Link to={'/redacoes/' + item.id}><span className="btn btn-success btn-outline btn-circle m-r-5"
                                                        data-toggle="tooltip"
                                                    ><i className="mdi mdi-eye"></i></span></Link>
                                                </td>

                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {this.state.candidatos.length == 0 && <div className="row w-100">
                            <div className="col-12">
                                <p style={{ textAlign: 'center' }}>Nenhum candidato nesta sala</p>

                            </div>
                        </div>}
                    </div>}

                    {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div></div>}



                </div>

            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
    }
);

export default connect(mapsStateToProps, { mudarUser, logout })(ListaCandidatos);
